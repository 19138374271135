export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "audioCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the above recorded response, I was able to <b>hear myself</b> clearly."])},
        "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
        "proceedToPractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed to practice"])},
        "reRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-record video"])},
        "recording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recording"])},
        "recordingNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please speak clearly and ensure your webcam is not obstructed in any way."])},
        "scriptDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The aim of Snapshot is to mimic the setting of an asychronous virtual interview to give programs a glimpse into your communication and interpersonal skills via video response. This is also an opportunity for you to demonstrate your personal attributes and motivation to the programs you are applying to."])},
        "scriptTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please read the below script:"])},
        "srOpenNewTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(opens in a new tab)"])},
        "startRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start recording"])},
        "startSystemCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start system requirements check"])},
        "stopRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop recording"])},
        "successfullyCompletedStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've successfully completed<br/>Step 2: System Requirements Check!"])},
        "systemCheckDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Completing this System Requirements Check is a required step and is to ensure you have a smooth experience completing Snapshot.</p><p>You'll be guided to record a short video of yourself, while reading a provided script out loud, watch the playback and upload the video to ensure your computer is running smoothly. Note that the video you record will not be shown to programs and is simply to ensure you pass the systems requirement check.</p><p><strong>Important: You should use the same computer and internet connection to run this check as as you would when completing Snapshot.</strong></p><p>In order to complete this check (and Snapshot), you will need:</p><ul><li>An updated Chrome browser is recommended. Firefox 80+ is a good back-up browser if you run into trouble.</li><li>Stable high speed internet connection.</li><li>Audio output (speakers or headphones) and input (microphone).</li><li>Webcam.</li></ul>"])},
        "systemCheckSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This ensures your computer is ready to take the test."])},
        "systemCheckTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Requirements Check"])},
        "troubleshootingTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troubleshooting Tips"])},
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
        "uploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to upload. Please try again or visit our Troubleshooting Tips."])},
        "uploadSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload successful"])},
        "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading..."])},
        "videoCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the above recorded response, I was able to <b>see myself</b> clearly."])}
      },
      "fr": {
        "audioCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je peux <b>m'entendre</b> clairement dans la réponse enregistrée ci-dessus."])},
        "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à l'accueil"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
        "proceedToPractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer vers l'entraînement"])},
        "reRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ré-enregistrer la vidéo"])},
        "recording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement"])},
        "recordingNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez parler de manière claire et vous assurer que votre webcam est découverte."])},
        "scriptDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'objectif de Snapshot est de reproduire un environnement d'entrevue asynchrone en ligne, pour offrir aux programmes un aperçu de vos aptitudes en communication et de vos compétences interpersonnelles grâce à des réponses vidéos. C'est également une occasion pour vous de démontrer vos qualités personnelles et votre motivation aux programmes"])},
        "scriptTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez lire le texte ci-dessous :"])},
        "srOpenNewTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ouvrir dans un nouvel onglet)"])},
        "startRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer l'enregistrement"])},
        "startSystemCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer la vérification du système"])},
        "stopRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrêter l'enregistrement"])},
        "successfullyCompletedStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez complété<br />L'étape 2 : Vérification du système !"])},
        "systemCheckDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>La vérification du système est une étape obligatoire, et vous assure de pouvoir compléter Snapshot sans rencontrer de problèmes.</p><p>Nous vous demanderons d'enregistrer une vidéo de vous-même en train de lire un texte à voix haute, puis de regarder l'enregistrement et de téléverser la vidéo pour vous assurer que votre ordinateur fonctionne correctement. Notez que cet enregistrement vidéo ne sera pas montré à vos programmes, et que son objectif est seulement de compléter la vérification du système.</p><p><strong>Important : vous devrez utiliser le même ordinateur et la même connexion internet pour effectuer cette vérification et pour compléter Snapshot.</strong></p><p>Pour effectuer cette vérification (et compléter Snapshot), vous aurez besoin de :</p><ul><li>La dernière mise à jour du navigateur Chrome est recommandée. Firefox 80+ est un bon navigateur de dépannage si vous rencontrez des difficultés avec Chrome.</li><li>Une connexion internet stable et rapide.</li><li>Des périphériques audios de sortie (enceintes, écouteurs ou casque audio) et d'entrée (microphone)</li><li>Une webcam</li></ul>"])},
        "systemCheckSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cela vous permet de vous assurer que votre ordinateur est prêt à passer l'entrevue."])},
        "systemCheckTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification du système"])},
        "troubleshootingTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseils de dépannage"])},
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléverser la vidéo"])},
        "uploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le téléversement a échoué. Veuillez réessayer ou lire nos Conseils de dépannage."])},
        "uploadSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléversement réussi"])},
        "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléversement..."])},
        "videoCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je peux <b>me voir</b> clairement dans la réponse enregistrée ci-dessus."])}
      }
    }
  })
}
