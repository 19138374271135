<template>
  <a-header v-if="!isUploaded" dotted class="py-12">
    <div class="w-11/12 md:w-4/5 xl:w-4/6 mx-auto">
      <h1 class="text-4xl text-white">
        {{ t('systemCheckTitle') }}
      </h1>
      <p class="mt-3 text-white" role="doc-subtitle">
        {{ t('systemCheckSubtitle') }}
      </p>
    </div>
  </a-header>
  <main class="w-11/12 md:w-4/5 xl:w-4/6 mx-auto mt-12">
    <div v-if="!isUploaded">
      <a-collapsible-card
        v-if="!isCapturing && !isRecorded && mediaError === null"
        expanded
        fixed
      >
        <h2 class="text-xl text-blue-darkest">{{ t('systemCheckTitle') }}</h2>
        <template #content>
          <div
            class="prose mt-4 pr-4 text-base"
            v-html="t('systemCheckDescription')"
            data-cy="txt-practice"
          />
          <a-button
            v-if="!isCapturing"
            class="mt-10 mb-1 block mx-auto"
            @click="startCapturing()"
            data-cy="button-start-system-check"
          >
            {{ t('startSystemCheck') }}
          </a-button>
        </template>
      </a-collapsible-card>
      <VideoRecorderError
        v-if="mediaError !== null"
        :error="mediaError"
        class="w-1/2 mx-auto mt-8"
        @retry="startCapturing()"
      />
      <div class="mt-10" v-else-if="isCapturing">
        <a-alert variant="info" dismissible class="w-full lg:w-3/4 xl:w-2/3 mx-auto">
          <p data-cy="alert-speak-clearly-webcam-not-obstructed">
            {{ t('recordingNotice') }}
          </p>
        </a-alert>
        <div class="mt-10 text-blue-darkest text-base">
          <div v-if="!isRecording" class="text-center" data-cy="txt-preview">
            {{ t('preview') }}
          </div>
          <div v-else class="flex justify-center items-center">
            <RecordingIndicator class="mr-2" v-if="isRecording" data-cy="txt-recording" />
            {{ t('recording') }}
          </div>
        </div>
        <div class="mt-3 sm:w-3/4 lg:w-1/2 mx-auto text-center">
          <VideoPreview :stream="stream ?? undefined" />
        </div>
        <div class="mt-6">
          <a-button
            v-if="!isRecording"
            class="mx-auto block"
            @click="startRecording()"
            data-cy="button-start-recording"
          >
            {{ t('startRecording') }}
          </a-button>
          <div v-else-if="!isRecorded">
            <a-card class="w-3/4 mx-auto" data-cy="card-read-script">
              {{ t('scriptTitle') }}
              <template #content>
                {{ t('scriptDescription') }}
              </template>
            </a-card>
            <a-button
              class="mt-6 mx-auto block"
              @click="stopRecording()"
              :disabled="isStopRecordingButtonDisabled"
              variant="danger"
              data-cy="button-stop-recording"
            >
              {{ t('stopRecording') }}
            </a-button>
          </div>
        </div>
      </div>
      <div v-else-if="isRecorded" class="mt-10">
        <div class="mt-4 sm:w-3/4 lg:w-1/2 mx-auto">
          <VideoPlayer controls :src="videoSrc ?? undefined" />
          <div v-if="!isUploading">
            <button
              class="mt-3 text-blue text-sm font-bold block mx-auto re-record-button"
              @click="startCapturing()"
              data-cy="button-re-record-video"
            >
              {{ t('reRecord') }}
            </button>
            <div class="mt-6 px-3">
              <div>
                <a-checkbox v-model="audioWorking" data-cy="checkbox-audio-working">
                  <span v-html="t('audioCheck')"></span>
                </a-checkbox>
              </div>
              <div class="mt-2">
                <a-checkbox v-model="videoWorking" data-cy="checkbox-video-working">
                  <span v-html="t('videoCheck')"></span>
                </a-checkbox>
              </div>
              <a-button
                :disabled="!audioWorking || !videoWorking"
                class="mt-8 block mx-auto"
                @click="uploadVideo()"
                data-cy="button-upload"
              >
                {{ t('upload') }}
              </a-button>
            </div>
          </div>
          <div
            v-else
            class="w-3/4 mx-auto mt-8 text-center"
            data-cy="container-upload-progress-bar"
          >
            <span class="text-sm text-blue-darkest">{{ t('uploading') }}</span>
            <a-progress-bar
              variant="secondary"
              show-number
              :percentage="percentageUploaded"
            ></a-progress-bar>
          </div>
        </div>
        <a-alert v-if="uploadError" class="mt-4 w-3/5 mx-auto" variant="danger" icon>
          {{ t('uploadError') }}
        </a-alert>
        <div class="text-center mt-6">
          <router-link
            :to="{ name: 'applicant.troubleshooting' }"
            target="_blank"
            class="link new-tab-link text-sm font-bold"
            data-cy="link-troubleshooting-tips"
          >
            {{ t('troubleshootingTips') }}
            <span class="sr-only">{{ t('srOpenNewTab') }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <div v-if="isUploaded" class="mt-20 w-1/2 mx-auto">
      <SuccessfullyCompleted data-cy="container-system-check-successfully-completed">
        <h1 data-cy="txt-upload-successful">{{ t('uploadSuccessful') }}</h1>
        <template #content>
          <h2 v-html="t('successfullyCompletedStep2')" class="mt-2 text-center"></h2>
          <div class="mt-8">
            <a-button
              link
              :to="{ name: 'applicant.home' }"
              variant="outline"
              data-cy="button-back-to-home"
            >
              {{ t('backToHome') }}
            </a-button>
            <a-button
              link
              :to="{ name: 'applicant.practiceTest' }"
              class="ml-4"
              data-cy="button-proceed-to-practice"
            >
              {{ t('proceedToPractice') }}
            </a-button>
          </div>
        </template>
      </SuccessfullyCompleted>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, ref } from 'vue';
import { useI18n } from '@/services/i18n';
import { injectApiClient } from '@/services/apiClient';
import { useStore } from '@/store/lib/store';
import { applicantStore } from '@/store/applicant';

import VideoRecorderError from '@/components/common/VideoRecorderError.vue';
import VideoPlayer from '@/components/common/VideoPlayer.vue';
import VideoPreview from '@/components/common/VideoPreview.vue';
import videoRecorder from '@/components/mixins/videoRecorder';
import SuccessfullyCompleted from '@/components/common/SuccessfullyCompleted.vue';
import RecordingIndicator from '@/components/common/RecordingIndicator.vue';

export default defineComponent({
  name: 'ApplicantSystemCheck',
  components: {
    VideoPlayer,
    VideoPreview,
    SuccessfullyCompleted,
    RecordingIndicator,
    VideoRecorderError
  },
  setup() {
    const t = useI18n().t;
    const isRecorded = ref(false);

    const mediaError = ref<Error | null>(null);

    const stream = ref<MediaStream | null>(null);
    const videoSrc = ref<{ src: string; type: string } | null>(null);
    let video: Blob | null = null;

    const audioWorking = ref(false);
    const videoWorking = ref(false);

    const recorder = videoRecorder();
    const isStopRecordingButtonDisabled = ref(false);
    const { isCapturing, isRecording, codec } = recorder;

    const client = injectApiClient();

    const applicant = useStore(applicantStore);

    const percentageUploaded = ref(0);
    const uploadError = ref(false);
    const isUploading = ref(false);
    const isUploaded = ref(false);

    async function startCapturing() {
      try {
        stream.value = await recorder.startCapturing();
        mediaError.value = null;
      } catch (error) {
        mediaError.value = error as Error;
      }
    }

    async function startRecording() {
      isRecorded.value = false;
      if (videoSrc.value !== null) {
        window.URL.revokeObjectURL(videoSrc.value.src);
      }
      await recorder.startRecording();
      isStopRecordingButtonDisabled.value = false;
    }

    async function stopRecording() {
      isStopRecordingButtonDisabled.value = true;
      ({ video } = await recorder.stopRecording());
      isRecorded.value = true;
      recorder.stopCapturing();
      videoSrc.value = {
        src: window.URL.createObjectURL(video),
        type: codec
      };
      isStopRecordingButtonDisabled.value = false;
    }

    async function uploadVideo() {
      isUploaded.value = false;
      isUploading.value = false;
      percentageUploaded.value = 0;
      if (video === null) {
        throw new Error('Video is missing');
      }
      try {
        uploadError.value = false;
        isUploading.value = true;
        await client.uploadSystemCheckVideo(
          video,
          percentage => (percentageUploaded.value = Math.round(percentage))
        );
        isUploaded.value = true;
        isUploading.value = false;
        applicant.markSystemCheckDone();
      } catch (error) {
        isUploading.value = false;
        isUploaded.value = false;
        uploadError.value = true;
        throw error;
      }
    }

    onBeforeUnmount(() => {
      if (videoSrc.value !== null) {
        window.URL.revokeObjectURL(videoSrc.value.src);
      }
    });

    return {
      t,
      mediaError,
      isCapturing,
      isRecording,
      isRecorded,
      stream,
      startCapturing,
      startRecording,
      stopRecording,
      isStopRecordingButtonDisabled,
      videoSrc,
      audioWorking,
      videoWorking,
      uploadVideo,
      percentageUploaded,
      uploadError,
      isUploading,
      isUploaded
    };
  }
});
</script>

<style lang="postcss" scoped>
.re-record-button {
  &:focus {
    @apply outline-none;
  }

  &:focus-visible {
    @apply outline-none ring-2 ring-blue;
  }

  &:-moz-focusring {
    @apply outline-none ring-2 ring-blue;
  }
}
</style>

<i18n>
{
  "en": {
    "audioCheck": "In the above recorded response, I was able to <b>hear myself</b> clearly.",
    "backToHome": "Back to home",
    "preview": "Preview",
    "proceedToPractice": "Proceed to practice",
    "reRecord": "Re-record video",
    "recording": "Recording",
    "recordingNotice": "Please speak clearly and ensure your webcam is not obstructed in any way.",
    "scriptDescription": "The aim of Snapshot is to mimic the setting of an asychronous virtual interview to give programs a glimpse into your communication and interpersonal skills via video response. This is also an opportunity for you to demonstrate your personal attributes and motivation to the programs you are applying to.",
    "scriptTitle": "Please read the below script:",
    "srOpenNewTab": "(opens in a new tab)",
    "startRecording": "Start recording",
    "startSystemCheck": "Start system requirements check",
    "stopRecording": "Stop recording",
    "successfullyCompletedStep2": "You've successfully completed<br/>Step 2: System Requirements Check!",
    "systemCheckDescription": "<p>Completing this System Requirements Check is a required step and is to ensure you have a smooth experience completing Snapshot.</p><p>You'll be guided to record a short video of yourself, while reading a provided script out loud, watch the playback and upload the video to ensure your computer is running smoothly. Note that the video you record will not be shown to programs and is simply to ensure you pass the systems requirement check.</p><p><strong>Important: You should use the same computer and internet connection to run this check as as you would when completing Snapshot.</strong></p><p>In order to complete this check (and Snapshot), you will need:</p><ul><li>An updated Chrome browser is recommended. Firefox 80+ is a good back-up browser if you run into trouble.</li><li>Stable high speed internet connection.</li><li>Audio output (speakers or headphones) and input (microphone).</li><li>Webcam.</li></ul>",
    "systemCheckSubtitle": "This ensures your computer is ready to take the test.",
    "systemCheckTitle": "System Requirements Check",
    "troubleshootingTips": "Troubleshooting Tips",
    "upload": "Upload",
    "uploadError": "Unable to upload. Please try again or visit our Troubleshooting Tips.",
    "uploadSuccessful": "Upload successful",
    "uploading": "Uploading...",
    "videoCheck": "In the above recorded response, I was able to <b>see myself</b> clearly."
  },
  "fr": {
    "audioCheck": "Je peux <b>m'entendre</b> clairement dans la réponse enregistrée ci-dessus.",
    "backToHome": "Retourner à l'accueil",
    "preview": "Aperçu",
    "proceedToPractice": "Continuer vers l'entraînement",
    "reRecord": "Ré-enregistrer la vidéo",
    "recording": "Enregistrement",
    "recordingNotice": "Veuillez parler de manière claire et vous assurer que votre webcam est découverte.",
    "scriptDescription": "L'objectif de Snapshot est de reproduire un environnement d'entrevue asynchrone en ligne, pour offrir aux programmes un aperçu de vos aptitudes en communication et de vos compétences interpersonnelles grâce à des réponses vidéos. C'est également une occasion pour vous de démontrer vos qualités personnelles et votre motivation aux programmes",
    "scriptTitle": "Veuillez lire le texte ci-dessous :",
    "srOpenNewTab": "(ouvrir dans un nouvel onglet)",
    "startRecording": "Commencer l'enregistrement",
    "startSystemCheck": "Commencer la vérification du système",
    "stopRecording": "Arrêter l'enregistrement",
    "successfullyCompletedStep2": "Vous avez complété<br />L'étape 2 : Vérification du système !",
    "systemCheckDescription": "<p>La vérification du système est une étape obligatoire, et vous assure de pouvoir compléter Snapshot sans rencontrer de problèmes.</p><p>Nous vous demanderons d'enregistrer une vidéo de vous-même en train de lire un texte à voix haute, puis de regarder l'enregistrement et de téléverser la vidéo pour vous assurer que votre ordinateur fonctionne correctement. Notez que cet enregistrement vidéo ne sera pas montré à vos programmes, et que son objectif est seulement de compléter la vérification du système.</p><p><strong>Important : vous devrez utiliser le même ordinateur et la même connexion internet pour effectuer cette vérification et pour compléter Snapshot.</strong></p><p>Pour effectuer cette vérification (et compléter Snapshot), vous aurez besoin de :</p><ul><li>La dernière mise à jour du navigateur Chrome est recommandée. Firefox 80+ est un bon navigateur de dépannage si vous rencontrez des difficultés avec Chrome.</li><li>Une connexion internet stable et rapide.</li><li>Des périphériques audios de sortie (enceintes, écouteurs ou casque audio) et d'entrée (microphone)</li><li>Une webcam</li></ul>",
    "systemCheckSubtitle": "Cela vous permet de vous assurer que votre ordinateur est prêt à passer l'entrevue.",
    "systemCheckTitle": "Vérification du système",
    "troubleshootingTips": "Conseils de dépannage",
    "upload": "Téléverser la vidéo",
    "uploadError": "Le téléversement a échoué. Veuillez réessayer ou lire nos Conseils de dépannage.",
    "uploadSuccessful": "Téléversement réussi",
    "uploading": "Téléversement...",
    "videoCheck": "Je peux <b>me voir</b> clairement dans la réponse enregistrée ci-dessus."
  }
}
</i18n>
