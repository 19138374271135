
import { defineComponent, onBeforeUnmount, ref } from 'vue';
import { useI18n } from '@/services/i18n';
import { injectApiClient } from '@/services/apiClient';
import { useStore } from '@/store/lib/store';
import { applicantStore } from '@/store/applicant';

import VideoRecorderError from '@/components/common/VideoRecorderError.vue';
import VideoPlayer from '@/components/common/VideoPlayer.vue';
import VideoPreview from '@/components/common/VideoPreview.vue';
import videoRecorder from '@/components/mixins/videoRecorder';
import SuccessfullyCompleted from '@/components/common/SuccessfullyCompleted.vue';
import RecordingIndicator from '@/components/common/RecordingIndicator.vue';

export default defineComponent({
  name: 'ApplicantSystemCheck',
  components: {
    VideoPlayer,
    VideoPreview,
    SuccessfullyCompleted,
    RecordingIndicator,
    VideoRecorderError
  },
  setup() {
    const t = useI18n().t;
    const isRecorded = ref(false);

    const mediaError = ref<Error | null>(null);

    const stream = ref<MediaStream | null>(null);
    const videoSrc = ref<{ src: string; type: string } | null>(null);
    let video: Blob | null = null;

    const audioWorking = ref(false);
    const videoWorking = ref(false);

    const recorder = videoRecorder();
    const isStopRecordingButtonDisabled = ref(false);
    const { isCapturing, isRecording, codec } = recorder;

    const client = injectApiClient();

    const applicant = useStore(applicantStore);

    const percentageUploaded = ref(0);
    const uploadError = ref(false);
    const isUploading = ref(false);
    const isUploaded = ref(false);

    async function startCapturing() {
      try {
        stream.value = await recorder.startCapturing();
        mediaError.value = null;
      } catch (error) {
        mediaError.value = error as Error;
      }
    }

    async function startRecording() {
      isRecorded.value = false;
      if (videoSrc.value !== null) {
        window.URL.revokeObjectURL(videoSrc.value.src);
      }
      await recorder.startRecording();
      isStopRecordingButtonDisabled.value = false;
    }

    async function stopRecording() {
      isStopRecordingButtonDisabled.value = true;
      ({ video } = await recorder.stopRecording());
      isRecorded.value = true;
      recorder.stopCapturing();
      videoSrc.value = {
        src: window.URL.createObjectURL(video),
        type: codec
      };
      isStopRecordingButtonDisabled.value = false;
    }

    async function uploadVideo() {
      isUploaded.value = false;
      isUploading.value = false;
      percentageUploaded.value = 0;
      if (video === null) {
        throw new Error('Video is missing');
      }
      try {
        uploadError.value = false;
        isUploading.value = true;
        await client.uploadSystemCheckVideo(
          video,
          percentage => (percentageUploaded.value = Math.round(percentage))
        );
        isUploaded.value = true;
        isUploading.value = false;
        applicant.markSystemCheckDone();
      } catch (error) {
        isUploading.value = false;
        isUploaded.value = false;
        uploadError.value = true;
        throw error;
      }
    }

    onBeforeUnmount(() => {
      if (videoSrc.value !== null) {
        window.URL.revokeObjectURL(videoSrc.value.src);
      }
    });

    return {
      t,
      mediaError,
      isCapturing,
      isRecording,
      isRecorded,
      stream,
      startCapturing,
      startRecording,
      stopRecording,
      isStopRecordingButtonDisabled,
      videoSrc,
      audioWorking,
      videoWorking,
      uploadVideo,
      percentageUploaded,
      uploadError,
      isUploading,
      isUploaded
    };
  }
});
